import React from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "utils/localStorageService";

const TermsAndCondition = () => {
  const navigate = useNavigate();

  const isLoggedIn = getItem('staking_access_token')
  const goBack = () => {
    if (Boolean(isLoggedIn)) {
      navigate('/dashboard');
    } else {
      navigate('/')
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="Terms-container container">
            <div>
              <button className="btn btn-light" onClick={goBack}>
                Go Back
              </button>
            </div>
            <div
              style={{ paddingBottom: "30px", paddingTop: "30px" }}
              className="text-center"
            >
              <h2>Harambee TERMS OF USE</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div style={{ paddingBottom: "20px" }}>
                  The Terms of Use of this Staking Platform (the “Platform”) is
                  entered between you (hereinafter referred to as “you” or
                  “your”) and Bantu Blockchain Foundation (“Bantu”). By
                  accessing, using, or clicking on “I agree” to use this
                  Platform, you agree that you have read, understood, and
                  accepted all of the terms and conditions stipulated in these
                  Terms of Use (these “Terms”) as well as our Privacy Policy at{" "}
                  <a target="_blank" rel="noreferrer" href="https://bantufoundation.org/privacy/">
                    https://bantufoundation.org/privacy/
                  </a>
                  .
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  In addition, when using some features of this Platform, you
                  may be subject to specific additional terms and conditions
                  applicable to those features.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  Please read the terms carefully as they govern your use of
                  this Platform.
                </div>
                <h6 style={{ paddingBottom: "20px" }}>
                THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF LEGALLY BINDING ARBITRATION.
                </h6>
                <div style={{ paddingBottom: "20px" }}>
                The terms of the arbitration provision are set forth in Article 8, “Dispute Resolution” hereunder.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                As with any Digital Asset, the value of Bantu (XBN) may fluctuate significantly and there is a substantial risk of economic losses when purchasing, selling, holding, investing or staking Bantu (XBN) and its derivatives.
                </div>
                <h6 style={{ paddingBottom: "20px" }}>
                BY MAKING USE OF SERVICES PROVIDED BY THIS PLATFORM, YOU ACKNOWLEDGE AND AGREE THAT:
                <ol>
                  <li>YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF BANTU (XBN) AND ITS DERIVATIVES;</li>
                  <li>YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF THIS PLATFORM;</li>
                  <li>BANTU BLOCKCHAIN FOUNDATION SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</li>
                </ol>
                </h6>
                <div style={{ paddingBottom: "20px" }}>
                By accessing, using, or attempting to use this Platform in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access this Platform, or utilize the services provided by this platform.
                </div>
                <h6 style={{ paddingBottom: "20px" }}>
                  I. DEFINITIONS
                </h6>
                <ol style={{paddingBottom: "20px"}}>
                  <li><b>1. Bantu Blockchain Foundation (“Bantu”)</b> refers to an ecosystem comprising Bantu websites (whose domain names include but are not limited to https://www.bantufoundation.org), mobile applications ( such as BantuPay Wallet <a target="_blank" rel="noreferrer" href="https://bantupay.org/">BantuPay</a>), clients, applets and other applications that are developed to offer Bantu Services, and includes independently operated platforms, websites and clients within the ecosystem such as Timbuktu Trading Platform (<a target="_blank" rel="noreferrer" href="https://timbuktu.exchange/#/">Timbuktu</a>) and Bantu Games ( <a target="_blank" rel="noreferrer" href="https://mitambo.net/#/">Mitambo</a>). In case of any inconsistency between relevant terms of use of the above platforms and the contents of these Terms, the respective applicable terms of such platforms shall prevail.</li>
                 <br/>
                 <li><b>2. Bantu Services</b> refers to various services provided to you by Bantu that are based on Internet and/or blockchain technologies and offered via Bantu websites, mobile applications, clients, and other forms (including new ones enabled by future technological development). Bantu Services include but not limited to such Bantu ecosystem components as BantuPay Wallet, Dashboard, Explorer, Laboratory, BantuTalk Forum, Bantu Network Token (XBN), Bantu Network Reward (BNR), Token Creator, Trading Platform, Games (Mitambo) and novel services to be provided by Bantu.</li>
                 <br/>
                 <li><b>3. Bantu Platform Rules</b> refers to all rules, interpretations, announcements, statements, letters of consent and other contents that have been and will be subsequently released by Bantu, as well as all regulations, implementation rules, product process descriptions, and announcements published in the Help Center or within products or service processes.</li>
                 <br/>
                 <li><b>4. Users</b> refers to all individuals, institutions or organizations that access, use, click on this Platform and Bantu website or Services and who meet the criteria and conditions stipulated by Bantu. If there exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies exchanges, such agreements shall be followed.</li>
                 <br/>
                 <li><b>5. Digital Currencies</b> refers to encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form.</li>
                 <br/>
                 <li><b>6. Digital Assets</b> refer to Digital Currencies, their derivatives, or other types of digitalized assets with a certain value.</li>
                 <br/>
                 <li><b>7. Bantu Accounts</b> refers to the non-custodial wallet (“BantuPay”) which records transactions, asset changes and basic information. The BantuPay wallet serves as the basis for Users to enjoy and exercise their rights on Bantu.</li>
                </ol>
                <h6 style={{ paddingBottom: "20px" }}>
                  II. GENERAL PROVISIONS
                </h6>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                1. About These Terms
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                a. Contractual Relationship
                </div>
                <div style={{ paddingBottom: "20px"}}>
                These Terms constitute a legal agreement and create a binding contract between you and Bantu.
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                b. Supplementary Terms
                </div>
                <div style={{ paddingBottom: "20px"}}>
                Due to the rapid development of Digital Currencies and Bantu, these Terms between you and Bantu do not enumerate or cover all rights and obligations of each party, and do not guarantee full alignment with needs arising from future development. Therefore, <b>THE PRIVACY POLICY, BANTU PLATFORM RULES, AND ALL OTHER AGREEMENTS ENTERED INTO SEPARATELY BETWEEN YOU AND BANTU ARE DEEMED SUPPLEMENTARY TERMS THAT ARE AN INTEGRAL PART OF THESE TERMS AND SHALL HAVE THE SAME LEGAL EFFECT. YOUR USE OF THIS PLATFORM AND BANTU SERVICES IS DEEMED YOUR ACCEPTANCE OF THE ABOVE SUPPLEMENTARY TERMS.</b>
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                c. Changes to These Terms
                </div>
                <div style={{ paddingBottom: "20px"}}>
                Bantu reserves the right to change or modify these Terms in its discretion at any time. Bantu will notify such changes by updating the terms on this Platform and its website and modifying the platform as required. 
                <br/>
                <br/>
                <b>ANY AND ALL MODIFICATIONS OR CHANGES TO THESE TERMS WILL BECOME EFFECTIVE UPON PUBLICATION ON THE PLATFORM AND WEBSITE OR RELEASE TO USERS. THEREFORE, YOUR CONTINUED USE OF THIS PLATFORM IS DEEMED YOUR ACCEPTANCE OF THE MODIFIED AGREEMENT AND RULES. IF YOU DO NOT AGREE TO ANY CHANGES TO THESE TERMS, YOU MUST STOP USING THE PLATFORM. YOU ARE RECOMMENDED TO FREQUENTLY REVIEW THESE TERMS TO ENSURE YOUR UNDERSTANDING OF THE TERMS THAT APPLY TO YOUR ACCESS TO AND USE OF THIS PLATFORM.</b>
                  
                </div><div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                d. Prohibition of Use
                </div>
                <div style={{ paddingBottom: "20px"}}>
                
 
 BY ACCESSING AND USING THIS PLATFORM, YOU REPRESENT AND WARRANT THAT YOU HAVE NOT BEEN INCLUDED IN ANY TRADE EMBARGOES OR ECONOMIC SANCTIONS LIST (SUCH AS THE UNITED NATIONS SECURITY COUNCIL SANCTIONS LIST), THE LIST OF SPECIALLY DESIGNATED NATIONALS MAINTAINED BY OFAC (THE OFFICE OF FOREIGN ASSETS CONTROL OF THE U.S. DEPARTMENT OF THE TREASURY), OR THE DENIED PERSONS OR ENTITY LIST OF THE U.S. DEPARTMENT OF COMMERCE. BANTU RESERVES THE RIGHT TO CHOOSE MARKETS AND JURISDICTIONS TO CONDUCT BUSINESS, AND MAY RESTRICT OR REFUSE, IN ITS DISCRETION, THE PROVISION OF BANTU SERVICES INCLUDING THIS PLATFORM IN CERTAIN COUNTRIES OR REGIONS.
  
 
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                2. About Bantu
                </div>
                <div style={{ paddingBottom: "20px"}}>
                As an important part of the Bantu Ecosystem, Bantu develops blockchain infrastructure and has applications and services which gives users access to the full functionality of the network and ecosystem. Bantu has the following applications and services ready to support the community to interact with the Bantu Blockchain Network: BantuPay Wallet, Dashboard, Explorer, Laboratory, BantuTalk Forum, Bantu Network Token (XBN), Bantu Network Reward (BNR) and the Bantu Token Creator, a Trading Platform, and a Game Platform (Mitambo).
 
 For more visit the website: www.bantufoundation.org
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                3.      Restriction
                </div>
                <div style={{ paddingBottom: "20px"}}>
               
                When you use this Platform, you agree and undertake to comply with the following provisions:
 
 i. During the use of the Platform, all activities you carry out should comply with the requirements of applicable laws and regulations, these Terms, and various guidelines of Bantu;
  
 ii. Your use of this Platform should not violate public interests, public morals, or the legitimate interests of others, including any actions that would interfere with, disrupt, negatively affect, or prohibit other Users from using the platform;
  
 iii. You agree not to use the platform for market manipulation whether prohibited by law or not;
  
 iv. Without written consent from Bantu, the following commercial uses of the Platform data are prohibited:
  
  Services that make use of Platform’s quotes or market bulletin board information;
  Data feeding or streaming services that make use of any market data of the Platform;
 Any other websites/apps/services that charge for or otherwise profit from (including through advertising or referral fees) market data obtained from the Platform.
  
 v. Without prior written consent from Bantu, you may not modify, replicate, duplicate, copy, download, store, further transmit, disseminate, transfer, disassemble, broadcast, publish, remove or alter any copyright statement or label, or license, sub-license, sell, mirror, design, rent, lease, private label, grant security interests in the properties or any part of the properties, or create their derivative works or otherwise take advantage of any part of the properties.
  
 vi. You may not (i) use any deep linking, web crawlers, bots, spiders or other automatic devices, programs, scripts, algorithms or methods, or any similar or equivalent manual processes to access, obtain, copy or monitor any part of the properties, or replicate or bypass the navigational structure or presentation of the Platform in any way, in order to obtain or attempt to obtain any materials, documents or information in any manner not purposely provided through the Platform; (ii) attempt to access any part or function of the properties without authorization, or connect to the Platform or any Bantu servers or any other systems or networks of any Bantu Services provided through the services by hacking, password mining or any other unlawful or prohibited means; (iii) probe, scan or test the vulnerabilities of the Platform or any Bantu network connected to the properties, or violate any security or authentication measures on the Platform or any network connected to Bantu Services; (iv) reverse look-up, track or seek to track any information of any other Users or visitors of the Platform; (v) take any actions that imposes an unreasonable or disproportionately large load on the infrastructure of systems or networks of Bantu Services or Bantu, or the infrastructure of any systems or networks connected to Bantu services; (vi) use any devices, software or routine programs to interfere with the normal operation of the Platform or any other Bantu Services or any other person’s use of Bantu Services; (vii) forge headers, impersonate, or otherwise manipulate identification, to disguise your identity or the origin of any messages or transmissions you send to the Platform , or (viii) use Platform or any other Bantu Services in an illegal way.
  
 By accessing the Platform, you agree that Bantu has the right to investigate any violation of these Terms, unilaterally determine whether you have violated these Terms, and take actions under relevant regulations without your consent or prior notice. Examples of such actions include, but are not limited to:
  
 i. Suspending the use of the BantuPay Wallet;
 ii. Reporting the incident to the relevant competent authorities;
 iii. Publishing the alleged violations and actions that have been taken;
 v. Deleting any information you published that are found to be violations.
                </div>


                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                5. Staking Program
                </div>
                <div style={{ paddingBottom: "20px" }}>
                Bantu will from time-to-time launch Staking Programs to reward, as per certain rules, Users who hold Bantu (XBN) and other such Digital Currencies in their BantuPay Wallet.
 
 When participating in Staking Programs, you should note that:
  
 Unless otherwise stipulated by Bantu, Staking Programs are free of charge and Users cannot trade he staked tokens during the staking period;
  Bantu does not guarantee Users’ proceeds under any Staking Program;
 Bantu has the right to initiate or terminate Staking Program or modify rules on such programs in its sole discretion;
 Users shall ensure that sources of the Bantu (XBN) or other Digital Currencies they hold in their BantuPay Wallet are legal and compliant and undertake to observe related laws and regulations. Otherwise, Bantu has the right to take necessary steps in accordance with these Terms or other Bantu Platform Rules, including, without limitation, suspending transactions on the BantuPay Wallet of the Users and restricting access to this Platform who violate the rules of respective Staking Programs.
 
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                III. LIABILITIES
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                1. Disclaimer of Warranties
                </div>

                <div style={{ paddingBottom: "20px" }}>
                  <b>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE PLATFORM SERVICES PROVIDED BY OR ON BEHALF OF BANTU ARE OFFERED ON AN “AS IS'' AND “AS AVAILABLE” BASIS, AND BANTU EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING, BANTU DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM IS ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
 
 EXCEPT FOR THE EXPRESS STATEMENTS, AGREEMENTS AND RULES SET FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THIS PLATFORM.
  
 WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT BANTU WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSETS PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C) INTERRUPTION IN ANY SUCH DATA, (D) REGULAR OR UNSCHEDULED MAINTENANCE CARRIED OUT BY BANTU AND SERVICE INTERRUPTION AND CHANGE RESULTING FROM SUCH MAINTENANCE, (E) ANY DAMAGES INCURRED BY OTHER USERS’ ACTIONS, OMISSIONS OR VIOLATION OF THESE TERMS, (F) ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS NOT AUTHORIZED BY BANTU ; AND (G) OTHER EXEMPTIONS MENTIONED IN DISCLAIMERS AND PLATFORM RULES ISSUED BY BANTU.
  
 THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO THE EXTENT IT IS PROHIBITED BY APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU RESIDE.
 </b>
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                2.  Disclaimer of Damages and Limitation of Liability
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <b>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BANTUBF, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT OF THE USE OF THIS PLATFORM, ANY PERFORMANCE OR NON-PERFORMANCE OF BANTU SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF BANTU AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF BANTU HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF BANTUBF’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
 
 NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF BANTU, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF SERVICES OFFERED BY OR ON BEHALF OF BANTU AND ITS AFFILIATES, ANY PERFORMANCE OR NON-PERFORMANCE OF BANTU SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID BY YOU TO BANTUBF UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.
 </b>
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                3.  Indemnification
                </div>
                <div style={{ paddingBottom: "20px" }}>
                You agree to indemnify and hold harmless Bantu, Bantu , their affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to (i) your use of, or conduct in connection with this Platform and other Bantu Services linked to this Platform, (ii) your breach or our enforcement of these Terms, or (iii) your violation of any applicable law, regulation, or rights of any third party during your use of this platform and other Bantu Services linked to this Platform. If you are obligated to indemnify Bantu, Bantu, their affiliates, contractors, licensors, and their respective directors, officers, employees, or agents pursuant to these Terms, Bantu will have the right, in its sole discretion, to control any action or proceeding and to determine whether Bantu wishes to settle, and if so, on what terms.
                    </div>
                    <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                IV.  ANNOUNCEMENTS
                </div>
                <div  style={{ paddingBottom: "20px" }}>
                Please be aware that all official announcements, news, promotions, competitions, and airdrops will be listed on https://bantufoundation.org/news-and-events/ and other social media groups such as https://t.me/bantublockchainAnn. USERS UNDERTAKE TO REFER TO THESE MATERIALS REGULARLY AND PROMPTLY. BANTUBF WILL NOT BE HELD LIABLE OR RESPONSIBLE IN ANY MANNER OF COMPENSATION SHOULD USERS INCUR PERSONAL LOSSES ARISING FROM IGNORANCE OR NEGLIGENCE OF THE ANNOUNCEMENTS.
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                V.  TERMINATION OF AGREEMENT
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                1.  Freezing the Staked Digital Asset
                </div>
                <div style={{ paddingBottom: "20px" }}>
                You agree that Bantu shall have the right to immediately and permanently freeze the Digital Asset(s) staked and cancel your authorization to the platform Due to Fraud, violation of these Terms, our Privacy Policy, or any applicable laws and regulations. You agree that Bantu shall not be liable to you for any permanent or temporary modification of any services linked to this Platform or suspension or termination of your access to all or any portion of Bantu Services linked to this Platform.
 
 Bantu maintains full custody of the Digital Assets which may be turned over to governmental authorities where such an event arises from fraud investigations, investigations of violation of law or violation of these Terms, and our Privacy Policy.
  
 The Bantu can equally permanently cancel your authorization to the platform:
  
 i. when these Terms are amended and you state your unwillingness to accept the amended Terms by applying for cancellation of your access to the platform.
  
 ii.  any other circumstances where Bantu deems it should terminate your access to the platform.
  
 Should your access to the Platform be canceled and saved in case of violation of these Terms, the staked rewards shall and when due be transferred to the BantuPay Wallet used to join the specific staking program.
 
  
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                2.   No Financial Advice
                </div>
                <div style={{ paddingBottom: "20px" }}>
                Bantu is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any activities effected by you using this Platform.
 
 No communication or information provided to you by Bantu is intended as, or shall be considered or construed as, investment advice, financial advice, staking advice, or any other sort of advice.
  
 Unless otherwise specified in these Terms, you are solely responsible for determining whether a staking program is appropriate for you according to your personal investment objectives, financial circumstances, and risk tolerance, and you shall be solely responsible for any loss or liability therefrom.
  
 You should consult legal or tax professionals regarding your specific situation. Before making the decision to stake any Digital Asset, you should conduct your own due diligence and consult your financial advisors prior to making any decision. Bantu will not be held responsible for the decisions you make to stake any Digital Asset based on the information provided by Bantu.
 
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                VI.   COMPLIANCE WITH LOCAL LAWS
                </div>
                <div style={{ paddingBottom: "20px" }}>
                
                It is Users’ responsibility to abide by local laws in relation to the legal usage of the Platform and Bantu Services linked to this Platform in their local jurisdiction as well as other laws and regulations applicable to Users. Users must also factor, to the extent of their local laws, all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities.
 
 ALL USERS OF THE PLATFORM AND OTHER BANTU SERVICES LINKED TO THE PLATFORM ACKNOWLEDGE AND DECLARE THAT THEIR DIGITAL ASSETS COME FROM LEGITIMATE SOURCES AND DO NOT ORIGINATE FROM ILLEGAL ACTIVITIES. USERS AGREE THAT BANTU WILL REQUIRE THEM TO PROVIDE OR OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO VERIFY THE LEGALITY OF THE SOURCES AND USE OF THEIR FUNDS.
  
 Bantu maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to freeze staked Digital Assets and Cancel Users’ access to the Platform especially for BantuPay wallets which have been flagged out or investigated by legal mandate.
 
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                VII.   DISPUTE RESOLUTION
                </div>
                <div style={{ paddingBottom: "20px" }}>
                This Agreement and any action arising out of your use of the Platform will be governed by and construed in accordance with the LAWS OF SEYCHELLES, unless submitted to arbitration as set forth in the following paragraph.
 
 PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING AS A CLASS ACTION.
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                1.   Notice of Claim and Dispute Resolution Period
                </div>
                <div style={{ paddingBottom: "20px" }}>
                   
Please contact Bantu first! Bantu wants to address your concerns without resorting to formal legal proceedings, if possible. If you have a dispute with Bantu, then you should contact Bantu, and a ticket number will be assigned.
 
 Bantu will attempt to resolve your dispute internally as soon as possible. The parties agree to negotiate in good faith to resolve the dispute (which discussions shall remain confidential and be subject to applicable rules protecting settlement discussions from use as evidence in any legal proceeding).
  
 In the event the dispute cannot be resolved satisfactorily, and you wish to assert a legal claim against Bantu, then you agree to set forth the basis of such claim in writing in a “Notice of Claim” as a form of prior notice to Bantu.
  
 The Notice of Claim must: (1) describe the nature and basis of the claim or dispute; (2) set forth the specific relief sought; (3) provide the original ticket number; and (4) include your email.
  
 The Notice of Claim should be submitted to an email address or hyperlink provided in your correspondence with Bantu. After you have provided the Notice of Claim to Bantu, the dispute referenced in the Notice of Claim may be submitted by either Bantu or you to arbitration in accordance with paragraph 2 of this Section, below.
 For the avoidance of doubt, the submission of a dispute to Bantu for resolution internally and the delivery of a Notice of Claim to Bantu are prerequisites to commencement of an arbitration proceeding (or any other legal proceeding).
  
 During arbitration, the amount of any settlement offer made by you or Bantu shall not be disclosed to the arbitrator.
 
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                2.   Agreement to Arbitrate and Governing Law
                </div>
                <div style={{ paddingBottom: "20px" }}>
                You and Bantu (and/or Bantu) agree that, subject to paragraph 1 above, any dispute, claim, or controversy between you and Bantu (and/or Bantu) arising in connection with or relating in any way to these Terms or to your relationship with Bantu (and/or Bantu) as a user of  the Staking Platform (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by mandatory final and binding individual (not class) arbitration, except as set forth below under Exceptions to Agreement to Arbitrate.
 
 You and Bantu (and/or Bantu) further agree that the arbitrator shall have the exclusive power to rule on his or her own jurisdiction, including without limitation any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court.
  <br/>
 <b>THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.</b>
  <br/>
 There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court (including, if applicable, attorney fees), except that the arbitrator may not award declaratory or injunctive relief in favour of anyone but the parties to the arbitration.
  
 The arbitration provisions set forth in this Section will survive termination of these Terms.
  
 Any arbitration will be conducted in the English language. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based.
 <br/>
 <b style={{ paddingBottom: "20px", fontWeight: 700 }}>TIME FOR FILING:</b>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                ANY ARBITRATION AGAINST BANTUBF (and/or BANTU) MUST BE COMMENCED BY FILING A REQUEST FOR ARBITRATION WITHIN ONE (1) YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION OR DEFAULT GIVING RISE TO THE CLAIM AND THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.
 
 THIS ONE YEAR LIMITATION PERIOD IS INCLUSIVE OF THE INTERNAL DISPUTE RESOLUTION PROCEDURE SET FORTH IN PARAGRAPH 1 OF THIS ARTICLE ABOVE. THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.
 
                </div>
                <div style={{ paddingBottom: "20px" }}>
                If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted within the shortest time permitted by applicable law.
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700 }}>
                PROCESS
                </div>
                <div style={{ paddingBottom: "20px" }}>
                1. Notice: The party who intends to seek arbitration after the expiration of the Dispute Resolution Period set forth in paragraph 1, above, must submit a request to an established alternative dispute resolution (“ADR”) provider mutually agreed upon by the Parties.
 
 If we request arbitration against you, we will give you notice at the email address you have provided. You agree that any notice sent to this email shall be deemed effective for all purposes, including without limitation to determinations of adequacy of service. It is your obligation to ensure that the email address on file with Bantu is up-to-date and accurate.
  
 The ADR provider and the parties must comply with the following rules: a) the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, as elected by the Party initiating the arbitration; b) the arbitration will not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the Parties, and c) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
 
                </div>
               
                <div style={{ paddingBottom: "20px" }}>
                  2. Seat of Arbitration: The seat of the arbitration shall be SEYCHELLES.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  3. Governing Law: These Terms (including this arbitration agreement) shall be governed by, and construed in accordance with, the laws of SEYCHELLES.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  4. Confidentiality: The parties agree that the arbitration shall be kept confidential. The existence of the arbitration, any nonpublic information provided in the arbitration, and any submissions, orders or awards made in the arbitration (together, the “Confidential Information”) shall not be disclosed to any non-party except the arbitrator, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to the conduct of the arbitration.
 
 Notwithstanding the foregoing, a party may disclose Confidential Information to the extent that disclosure may be required to fulfill a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision shall survive termination of these Terms and of any arbitration brought pursuant to these Terms
 
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  5. Class Action Waiver : You and Bantu (and/or Bantu)  agree that any claims relating to these Terms or to your relationship with Bantu (and/or Bantu) as a user of this Platform (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) shall be brought against the other party in an arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action
 
 You and Bantu (and/or Bantu) further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable law. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties, including Bantu.
 
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  6. Modifications:  Bantu reserves the right to update, modify, revise, suspend, or make any future changes to Article VIII regarding the parties’ Agreement to Arbitrate, subject to applicable law. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Section is up to date.
 
 Subject to the applicable law, your continued use of the Platform and other Bantu services linked to the Platform shall be deemed to be your acceptance of any modifications to Section VIII regarding the parties’ Agreement to Arbitrate. You agree that if you object to the modifications to Section VIII, Bantu may cancel/restrict your access to the Platform. In such circumstances, the Terms prior to modification shall remain in full force.
 
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  7. Severability:  If any portion of these Terms are adjudged to be invalid or unenforceable for any reason or to any extent, the remainder of these Terms will remain valid and enforceable, and the invalid or unenforceable portion will be given effect to the greatest extent permitted by law.
                </div>
                <div style={{ paddingBottom: "20px", fontWeight: 700  }}>
                  VIII. MISCELLANEOUS
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  1. Independent Parties: Bantu is an independent contractor but not an agent of you in the performance of these Terms. These Terms shall not be interpreted as facts or evidence of an association, joint venture, partnership, or franchise between the parties.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  2. Entire Agreement: These Terms constitute the entire agreement between the parties regarding use of the Platform and other Bantu Services linked to this Platform and will supersede all prior written or oral contrary agreements between the parties.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  3. Interpretation and Revision: Bantu reserves the right to alter, revise, modify, and/or change these Terms at any time. All changes will take effect immediately upon being published on Platform as well as on Bantu websites. It is your responsibility to regularly check relevant pages on our websites/applications to confirm the latest version of these Terms. If you do not agree to any such modifications, your only remedy is to terminate your usage of the Platform or Bantu Services linked to the Platform. You agree that, unless otherwise expressly provided in these Terms, Bantu will not be responsible for any modification or termination of Bantu Services by you or any third party, or suspension/restriction of your access to Platform or Bantu Services linked to the Platform.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  4. Force Majeure:  Bantu will not be liable for any delay or failure to perform as required by these Terms because of any cause or condition beyond Bantu’s reasonable control.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  5. Severability: If any portion of these Terms is held invalid or unenforceable, such invalidity or enforceability will not affect the other provisions of these Terms, which will remain in full force and effect, and the invalid or unenforceable portion will be given effect to the greatest extent possible.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  6. Assignment: You may not assign or transfer any right to use the Platform or other Bantu Services linked to the Platform or any of your rights or obligations under these Terms, including any right or obligation related to the enforcement of laws or the change of control. Bantu may assign or transfer any or all its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  7. Waiver: The failure of one party to require performance of any provision will not affect that party’s right to require performance at any time thereafter. At the same time, the waiver of one party to seek recovery for the other party’s violation of these Terms or any provision of applicable terms shall not constitute a waiver by that party of any subsequent breach or violation by the other party or of the provision itself.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  8. Third-Party Website Disclaimer.  Any links to third-party websites from the Platform or to Bantu Services linked to the Platform does not imply endorsement by Bantu of any product, service, information, or disclaimer presented therein, nor does Bantu guarantee the accuracy of the information contained on them. If you suffer loss from using such third-party products and services, Bantu will not be liable for such loss. In addition, since Bantu has no control over the terms of use or privacy policies of third-party websites, you should read and understand those policies carefully.
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  9. Contact Information.  For more information on Bantu, you may refer to the Bantu websites (Bantu Blockchain Foundation (bantufoundation.org). If you have questions regarding these Terms, please feel free to contact us through legal@bantufoundation.org
                </div>
               
              </div>
              <div>
              <button className="btn btn-light" onClick={goBack}>
                Go Back
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermsAndCondition;
