import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Nav from 'Components/DashboardComponents/Navigation';
import TopNavBar from 'Components/DashboardComponents/TopNavBar';
import Footer from 'Components/DashboardComponents/Footer';
import DashboardChild from './Dashboard';
import StakeComponent from './Stake';
import History from './History';
import Calculator from './Calculator';
import Loader from 'Components/Shared/Loader';

import { useStateValue } from 'providers/stateProvider';
import { getUserDetails } from 'providers/action/user';
import { getItem, setItem } from 'utils/localStorageService';
import { userOrderData } from 'utils/sockets';

const Dashboard = () => {

    const [showTC, setShowTC] = useState(false);
    const [acceptTC, setAcceptTC] = useState(false);

    const [{ user: { dashboard, userInfo, loggedIn, loadingUserData } }, dispatch] = Object.values(useStateValue());
    const { currentPage } = dashboard;

    useEffect(() => {
        const TCStatus = getItem('accepted-TC');
        if (!Boolean(TCStatus)) {
            setShowTC(true);
        }
    }, [])

    useEffect(() => {
        if (loggedIn) {
            userOrderData(dispatch);
        }
    }, [dispatch, loggedIn]);

    useEffect(() => {
        if (Object.keys(userInfo).length === 0) getUserDetails(dispatch);
    }, [dispatch, userInfo])

    const renderPage = () => {
        switch (currentPage) {
            case 'dashboard':
                return <DashboardChild userInfo={userInfo} />
            case 'stake':
                return <StakeComponent userInfo={userInfo} />
            case 'history':
                return <History stakingOrders={userInfo?.stakingStatus?.stakingOrders} />
            case 'calculator':
                return <Calculator name={userInfo?.stakingStatus?.stakingProgram?.program} />
            default:
                return <DashboardChild userInfo={userInfo} />
        }
    }

    const handleCheck = (e) => {
        setAcceptTC(e.target.checked);
    }

    const handleClose = () => {
        setShowTC(false);
        setItem('accepted-TC', acceptTC ? 1 : 0);
    }

    const pageLoader = () => {
        return (
            <React.Fragment>
                <div style={{width: "50%", margin: '5% auto', textAlign: 'center'}}>
                    <h2><Loader type="border" /> Loading...</h2>
                </div>

            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div>
                <TopNavBar userData={userInfo} />
                <Nav />
                <section className="main_content dashboard_part">
                    <div className="main_content_iner ">
                        <div className="container-fluid p-0">
                            <div className="row justify-content-center">
                                {loadingUserData ? pageLoader() : renderPage()}
                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <Footer />

            <Modal size="lg" show={showTC}>
                <Modal.Header>
                    <Modal.Title>Attention!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0">
                        Digital assets are not suitable for everyone.
                        <br />
                        The value of the Bantu Network Utility token (XBN) may fluctuate based on market conditions.
                        <br />
                        <br />
                        Taxes may be payable on any return on and/or any increase in the value of your digital assets. It is advisable to seek independent advice prior to acquiring any digital assets.
                        <br />  <br />
                        <input onClick={handleCheck} type="checkbox" /> I accept the <Link to='/terms-and-conditions'>terms and conditions</Link>
                        <br />
                        <br />
                        <br />
                        <Button disabled={!acceptTC} variant="primary" onClick={handleClose}>Proceed</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default Dashboard
