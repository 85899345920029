import React from 'react';
import Table from 'Components/DashboardComponents/Table';

const History = ({ stakingOrders }) => {
  return (
    <React.Fragment>
        <div className="col-xl-12">
            <div className="white_box QA_section card_height_100">
                <Table stakingOrders={stakingOrders} />
            </div>
        </div>
    </React.Fragment>
  )
}

export default History