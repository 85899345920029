import Swal from 'sweetalert2';

export const ShowConfirm = (title, icon, text, confirmButtonText, cancelButtonText='') => {

  const notification = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  return notification.fire({
      title,
      text,
      icon,
      confirmButtonText,
      cancelButtonText,
      showCancelButton: cancelButtonText !== '',
    })
}

export const notification = (title, icon='success', timer=1500) => {
  Swal.fire({
    position: 'top-end',
    icon,
    title,
    showConfirmButton: false,
    timer
  })
}