import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getRefreshToken } from 'utils/localStorageService';
import jwtDecode from "jwt-decode";

import { notification } from 'Components/Shared/Notification';

const ProtectedRoute = () => {

    const refreshToken = getRefreshToken();

    let decodedToken = null
    let validToken;

    if (refreshToken) {
        try {
          decodedToken = jwtDecode(refreshToken);
          validToken = new Date(decodedToken.exp * 1000) > new Date();

        } catch (e) {

        }
    }

    if (!validToken) {
      notification('Your session has expired. Please login again.', 'warning');
    }
    return validToken ? <Outlet /> : <Navigate to="/logout" />;
};

export default ProtectedRoute;
