import {
    loginRequest,
    verifyLoginRequest,
    getUserDetailsRequest,
    makeUserOrdersRequest,
} from 'api';
import userType from 'providers/reducers/user/type';
import { getItem } from 'utils/localStorageService'

const {
    INITIAL_LOGIN_REQUEST,
    INITIAL_LOGIN,
    LOGIN_ERROR,
    LOGIN_USER,
    LOOP_REQUEST,
    GET_PROFILE_REQUEST,
    GET_PROFILE,
    ERROR,
    MAKE_USER_ORDERS_REQUEST,
    MAKE_USER_ORDERS,
} = userType;

const userLogin = async (data, dispatch) => {
    try {
        dispatch({
            type: INITIAL_LOGIN_REQUEST,
        });
        const user = await loginRequest(data);
        const { dynamicLink, loginId, qrCode } = user.data;
        dispatch({
            type: INITIAL_LOGIN,
            payload: { dynamicLink, loginId, qrCode }
        });

        return user;
    } catch (e) {
        dispatch({
            type: LOGIN_ERROR,
            payload: e?.response?.data?.error
        });
    }
};

const verifyLogin = async (data, dispatch) => {
    try {
        const verifiedUser = await verifyLoginRequest(data);
        dispatch({
            type: LOGIN_USER,
            payload: verifiedUser.data
        });
    } catch (e) {
        dispatch({
            type: LOOP_REQUEST
        });
    }
}

const getUserDetails = async (dispatch, stake = false) => {
    try {
        if (!stake) {
            dispatch({
                type: GET_PROFILE_REQUEST
            });
        }
        const username = getItem('staking_username')
        const profileData = await getUserDetailsRequest({ username });
        dispatch({
            type: GET_PROFILE,
            payload: profileData?.data
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e?.response?.data?.message
        })
    }
}

const makeUserOrders = async (data, dispatch) => {
    try {
        dispatch({
            type: MAKE_USER_ORDERS_REQUEST
        });
        const orderData = await makeUserOrdersRequest(data);

        dispatch({
            type: MAKE_USER_ORDERS,
            payload: orderData?.data
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e?.response?.data?.message
        })
    }
}


export {
    userLogin,
    verifyLogin,
    getUserDetails,
    makeUserOrders,
}