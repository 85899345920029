import React from "react";
import {
    HashRouter,
    Routes,
    Route, 
} from "react-router-dom";

import PrivateRoute from "./ProtectedRoutes";

import HomePage from 'Pages/HomePage';
import Dashboard from 'Pages/Dashboard';
import ConnectWallet from "Pages/ConnectWalet";
import TermsAndCondition from "Pages/TermsAndCondition";

import Logout from "./Logout";


const AllRoutes = () => {
    return (
        <HashRouter>
            <Routes>
                <Route exact path="/" element={ <HomePage /> } />
                <Route exact path="/connect-wallet" element={ <ConnectWallet /> } />
                <Route exact path="/terms-and-conditions" element={ <TermsAndCondition /> } />

                <Route exact path='/' element={<PrivateRoute/>}>
                    <Route exact path="/dashboard" element={ <Dashboard /> } />
                </Route>

                <Route exact path="/logout" element={ <Logout /> } />
            </Routes>
        </HashRouter>
    );
};

export default AllRoutes;
