import React from 'react';
import moment from 'moment';
import { formatNum } from 'Components/utils/formatNumbers';

const Table = ({ stakingOrders }) => {
    const assetType = 'XBN'
  return (
    <React.Fragment>
        <div className="white_box QA_section card_height_100">
            <div className="white_box_tittle list_header m-0 align-items-center">
                <div className="main-title mb-sm-15">
                    <h3 className="m-0 nowrap">Transaction History</h3>
                </div>
                <div className="box_right d-flex lms_block">
                    {/* <div className="serach_field-area2">
                            <div className="search_inner">
                            <form active="#">
                                <div className="search_field">
                                    <input type="text" placeholder="Search here..." />
                                </div>
                                <button type="submit"> <i className="ti-search"></i> </button>
                            </form>
                        </div> 
                    </div>*/}
                </div>
            </div>
            <br/>
            <div className="QA_table table-responsive">
                { stakingOrders && stakingOrders.length > 0 ?
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Amount</th>
                            {/* <th scope="col">Expected returns</th> */}
                            <th scope="col">Time Deposited</th>
                            <th scope="col">Deposit Memo</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stakingOrders?.map((order, index) => {
                            // const a = moment(order.holdStartDate);
                            // const b = moment(order.holdEndDate);
                            // const diff = b.diff(a, 'months');
                            return( <tr key={index}>
                                <td>
                                    {formatNum(order.orderAmount)} {assetType}
                                </td>
                                {/* <td>
                                    {formatNum(order.orderExpectedReturn)} {assetType}
                                </td> */}
                                <td>{moment(order.createdAt).fromNow()}</td>
                                <td>{order.orderDepositMemo}</td>
                                <td>{Boolean(order.orderStatus) ? 'Completed' : 'Pending'}</td>
                            </tr>
                        )})}
                    </tbody>
                </table> : 
                  <div className="modal-content cs_modal"> 
                       <div className="modal-body">
                           <h3>No records available yet.</h3>
                        </div>
                  </div>
                }
            </div>
        </div>
    </React.Fragment>
  )
}

export default Table