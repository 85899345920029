import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import bantuLogoOrange from 'assets/bantuLogoOrange.png'
import bantuLogoWhite from 'assets/bantuLogoWhite.png'
import Trangle from 'assets/Rectangle 192.png'
import coinOne from 'assets/bantu coin.png';
import coinTwo from 'assets/bantu coin 2.png';
import join from 'assets/waitlist.jpeg';
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import BuyXBN from './BuyXBN';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const HomeComponent = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dynamicLink = 'https://bantupayapp.page.link/6Zzy';
  // const [showBeforeMarch, setShowBeforeMarch] = React.useState(false)
  // const [showAfterMarch, setShowAfterMarch] = React.useState(false)
  // const onClickBefore = () => {
  //   setShowBeforeMarch(true)
  //   setShowAfterMarch(false)
  // }
  // const onClickAfter = () => {
  //   setShowAfterMarch(true)
  //   setShowBeforeMarch(false)
  // }
  return (
    <React.Fragment>
      {/* Hero */}
      <div className='container'>
        <div className='row pb-5 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className='col-10 '>
          <img src={bantuLogoOrange} alt='' style={{
            width:
              "49.96px", position: 'absolute'
          }} /> <span className='xbn-text ms-5'>Harambee</span>
        </div>
        <div className='col'><Link to="/connect-wallet" className="btn btn-md btn-primary">Connect to BantuPay wallet</Link></div>
        </div>
        <div className='row'>
          <div className="col-md-6" style={{alignSelf: 'center'}}>
            <h6 className="gir_up" style={{fontSize: '58px'}}>
            Earn Passive
            </h6>
            <h2 className='apr'>
            Returns
            </h2>
            <h4 className='when_u'>WHEN YOU STAKE BANTU (XBN)</h4>
            {/* <h3 className='offer_valid'>Offer valid till 31st March, 2022</h3> */}

             <div className="">
               {/* <p className='terms_cond'>Connect your BantuPay wallet to get started</p>
               <br />
               <br /> */}
              <p className='terms_cond'>Terms and conditions apply</p>
            </div>
            

            <div  className="pt-5 pb-3">
          <span className='share_friends me-3'>Share with friends </span>
          <TelegramShareButton
            title="Earn up to 200% APR  when you stake Bantu (XBN). Go to stake.bantupay.org to get started. Offer valid till 31st March, 2022."
            url="https://stake.bantupay.org/"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <FacebookShareButton
            url={"https://stake.bantupay.org/"}
            quote={"Earn up to 200% APR  when you stake Bantu (XBN). Go to stake.bantupay.org to get started. Offer valid till 31st March, 2022. #Bantu #Staking #XBN #Defi #Harambee"}
            hashtag='#Harambee #Bantu #Staking #XBN #Defi'
            className="myShareCountWrapper">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            title="Earn up to 200% APR  when you stake Bantu (XBN). Go to stake.bantupay.org to get started. Offer valid till 31st March, 2022."
            url="https://stake.bantupay.org/"
            hashtags={['Harambee', 'Bantu', 'Staking', 'XBN', 'Defi']}
            via="https://stake.bantupay.org/"
            className="myShareCountWrapper"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton
            title="Bantu Staking Platform"
            summary="Earn up to 200% APR  when you stake Bantu (XBN). Go to stake.bantupay.org to get started. Offer valid till 31st March, 2022. #Bantu #Staking #XBN #Defi #Harambee"
            url="https://stake.bantupay.org/"
            source="https://stake.bantupay.org/"
            className="myShareCountWrapper"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <WhatsappShareButton
            title="Earn up to 200% APR  when you stake Bantu (XBN). Go to stake.bantupay.org to get started. Offer valid till 31st March, 2022"
            url="https://stake.bantupay.org/"
            className="myShareCountWrapper"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
          </div>
          <div className="col-md-6 d-none d-sm-block d-md-block d-md-none d-lg-block d-lg-none d-xl-block">
            <img style={{ width: '100%' }} src={Trangle} alt='' />
          </div>
        </div>
      </div>
      {/*  approved exchanges */}
      <div className="py-2 text-center" style={{ background: "rgba(21, 80, 166, 0.05)", padding: '10px' }}>
      <Carousel responsive={ responsive } autoPlay={ true } infinite={ true } showDots={true} arrows={ false } containerClass="carousel-container" >
        <div className='while-wait' style={{ fontSize: '20px' }}>“Opportunities come infrequently. When it rains gold, put out the bucket, not the thimble.” - Warren Buffet</div>
        <div className='while-wait' style={{ fontSize: '20px' }}>“The secret of getting ahead is getting started.”  - Mark Twain</div>
        <div className='while-wait' style={{ fontSize: '20px' }}>“The best time to plant a tree was 20 years ago. The second best time is today.”  - Ancient Chines Proverb</div>
        </Carousel>
        <br />
       
      </div>
   
      {/* How it works */}
      <div className="py-2 text-center" style={{ textAlign: 'center', color: '#ffffff', padding: '10px', background: '#153C76' }}>
        <div className="row">
          <div className="col-md-3">
            <div className="row">
              <img src={coinTwo} alt='' />

            </div>
          </div>
          <div className="col-md-6 text-center">
            <div style={{ padding: '35px 0' }}>
              <div className="" style={{ fontSize: '24px', fontWeight: 700 }}>
              Ongoing Harambee (staking) Sessions
              </div>
              <div className="pt-5">
                <span style={{ fontSize: '18px', color: '#fffff' }}>April 1st - Sep 31st
                </span>
              </div>
            </div>

            <div style={{ padding: '35px 0' }}>
            <div className="" >
              <span onClick={handleShow} className="btn btn-primary" style={{ width: '213px', justifyContent: 'center', color: '#1550A6', fontWeight: 400, margin: '20px', backgroundColor: '#ffffff' }}>Join waitlist for next session
              </span>
            </div>
            <div className="" >
             <p style={{color: '#ffffff', cursor:'pointer'}}>or <button type="button" className="btn btn-link" style={{ color: '#ffffff'}} onClick={() => window.open(dynamicLink, "_blank")}><u>Join waitlist with mobile</u></button></p>
            </div>
            </div>
              {/* <div className="" style={{ fontSize: '24px', fontWeight: 700 }}>
                Accounts created after March 1st, 2022
              </div>
              <div className="">
                <span onClick={onClickAfter} className="btn btn-primary" style={{ margin: '20px', fontWeight: 700, backgroundColor: '#153C76', border: '1px solid white' }}>Click Here
                </span>
              </div>
            </div> */}
            {/* <div >
              <div className="mb-3" style={{ fontSize: '24px', fontWeight: 700 }}>
                Accounts created before March 1st, 2022
              </div>
              <div className="">
                <span onClick={onClickBefore} className="btn btn-primary" style={{ margin: '20px', fontWeight: 700, backgroundColor: '#153C76', border: '1px solid white' }}>Click Here
                </span>
              </div>
            </div> */}
          </div>
 
          <div className="col-md-3">
          <div className="row">
              <img src={coinOne} alt=''/>

            </div>
          </div>
        </div>
      </div>
                  {/* Cards */}
     <BuyXBN />
      {/* Hoe it works cont*/}
   
        <div className="py-3" style={{ background: "rgba(21, 80, 166, 0.05)", display: 'flex', flexDirection: 'row-reverse', adding: '10px', textAlign: 'start' }}>
          <div className="col-md-4 d-none d-sm-block d-md-block d-md-none d-lg-block d-lg-none d-xl-block">

          </div>
          <div className="col-md-4z" style={{ padding: "50px 20px", color: '#4F4F4F' }}>
            <h3 style={{ paddingBottom: "20px", color: '#4F4F4F' }}>How it works for active Harambee sessions</h3>
            <div className="content" style={{ fontSize: '16px'}}>

        <div className="m-top-25">
        <ul className="check-list--one" style={{ fontSize: '16px'}}>
            <li className="list-item checklist icon-secondary">
              1.  Acquire XBN from any of the approved exchanges.
              </li>
            <li className="list-item checklist icon-secondary">
              2.  Send the newly acquired XBN to your BantuPay wallet.
              </li>
            <li className="list-item checklist icon-secondary">
              3.  Login to Harambee with your BantuPay wallet.
              </li>
            <li className="list-item checklist icon-secondary">
              4.  Click the Deposit XBN button and confirm the amount of XBN you wish to stake.
              </li>
            <li className="list-item checklist icon-secondary">
              5.  Authorise the transaction using BantuPay
              </li>
            <li className="list-item checklist icon-secondary">
              6.  Once you have staked your tokens, you can no longer unstake them till the staking period expires.
              </li>
            <li className="list-item checklist icon-secondary">
              7.  Maximum return available will be shown on your staking page once you login.
              </li>
          </ul>
          <p className="py-5">
          If you need any assistance, please visit <a target="_blank" rel="noreferrer" style={{color: '#007bff'}} href="https://t.me/banturewards">
          t.me/banturewards
                  </a>. Our team will be happy to guide you through.
        </p>
        </div>
      </div>
          </div>
          <div className="col-md-3 d-none d-sm-block d-md-block d-md-none d-lg-block d-lg-none d-xl-block">

          </div>
        </div>
      {/* {showAfterMarch ? (
               <div className="py-3" style={{ background: "rgba(21, 80, 166, 0.05)", display: 'flex', flexDirection: 'row-reverse', adding: '10px', textAlign: 'start' }}>
               <div className="col-md-4 d-none d-sm-block d-md-block d-md-none d-lg-block d-lg-none d-xl-block">
     
               </div>
               <div className="col-md-4z" style={{ padding: "50px 20px", color: '#4F4F4F' }}>
                 <h3 style={{ paddingBottom: "20px", color: '#4F4F4F' }}>How it works for accounts created after March 1st, 2022</h3>
                 <div className="content" style={{ fontSize: '16px'}}>
     
             <div className="m-top-25">
               <ul className="check-list--one" style={{ fontSize: '16px'}}>
                 <div className=" row list-item checklist icon-secondary pb-3">
                 <div className="col-lg-1" ><img src={one} alt='' style={{
                 width: "40px", paddingRight: '10px'}} /></div>
                   <div className="col" >Go to either <a href='https://www.bitmart.com/trade/en?symbol=XBN_USDT&layout=basic' rel="noreferrer" target="_blank">Bitmart</a>, <a href='https://fmfw.io/xbn-to-usdt' rel="noreferrer" target="_blank">FMFW.io</a>, <a href='https://global.bittrex.com/Market/Index?MarketName=USDT-XBN' rel="noreferrer" target="_blank">Bittrex Global</a> and <a href='https://exchange.tradefada.com/xbn-to-usdt' rel="noreferrer" target="_blank">Tradefada</a> to acquire the XBN you wish to stake and send them to your BantuPay wallet.</div>
                 </div>
                 <div className="row list-item checklist icon-secondary pb-3">
                 <div className="col-lg-1" ><img src={two} alt='' style={{
                 width: "40px", paddingRight: '10px'}} /></div>
                   <div className="col" >Enter the amount of XBN you wish to stake (for 6 months) in the Staking Section. You will see your expected returns automatically calculated.</div>
                 </div>
                 <div className=" row list-item checklist icon-secondary pb-3">
                 <div className="col-lg-1" ><img src={three} alt='' style={{
                 width: "40px", paddingRight: '10px'}} /></div>
                   <div className="col" >Click the button to deposit XBN for staking, and authorize the transaction.</div>
                 </div>
               </ul>
               <p className="py-3">
               You will have to wait till the 6 months staking period is over before you are able to withdraw your staked tokens and earned tokens to your wallet!
             </p>
               <p className="py-3">
               It’s really that simple.
             </p>
               <p className="py-3">
               <b>Important Information:</b>
             </p>
             <ul className="check-list--one" style={{ fontSize: '16px'}}>
                 <li className="list-item checklist icon-secondary">
                   -  This opportunity closes on 31st March, 2022.
                   </li>
                 <li className="list-item checklist icon-secondary">
                   -  XBN staked cannot be withdrawn until the staking period is over.
                   </li>
                 <li className="list-item checklist icon-secondary">
                   -  Accounts created after 1st March will be able to earn up to 200% APR depending on the quantity of approved XBN staked
                   </li>
                 <li className="list-item checklist icon-secondary">
                   -  Only the amount of XBN acquired from the approved exchanges will be eligible and approved for staking.
                   </li>
               </ul>
               <p className="py-5">
               If you need any assistance, please visit <a target="_blank" rel="noreferrer" style={{color: '#007bff'}} href="https://t.me/banturewards">
               t.me/banturewards
                       </a>. Our team will be happy to guide you through.
             </p>
             </div>
           </div>
               </div>
               <div className="col-md-3 d-none d-sm-block d-md-block d-md-none d-lg-block d-lg-none d-xl-block">
     
               </div>
             </div>) : null} */}
      {/* Footer*/}
      <div className="py-2 text-center" style={{ padding: '40px', background: '#11223C' }}>
        <div className="row" style={{ color: '#ffffff', padding: '20px 0' }}>
          <div className="col-sm">
            <div className='py-3 pb-5'>
              <img src={bantuLogoWhite} alt='' style={{ color: '#fff', width: "49.96px", position: 'absolute' }} /> <span className='xbn-text-footer ms-5'>Harambee</span>
            </div>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Legal & Privacy</div>
            <div><Link style={{ color: '#ffffff'}} to="/terms-and-conditions">Terms of Use</Link></div>
            <a style={{ color: '#ffffff'}} target="_blank" rel="noreferrer" href="https://bantufoundation.org/privacy/">Privacy Policy</a>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Support</div>
            <div>User Guide
            </div>
            <div>User Support</div>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Community</div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://t.me/bantublockchain" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faTelegram} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://web.facebook.com/bantublockchain?_rdc=1&_rdr" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faFacebook} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/bantublockchain" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faTwitter} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/bantublockchain/mycompany/" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>

            </div>
          </div>
        </div>


      </div>
      <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan QRCode to join waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    <div className="container-fluid pb-5">
                    <img src={join} alt='' style={{width: '300px'}} /> 
                        
                    </div>
                    <div>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
    </React.Fragment>
  );
}

export default HomeComponent;
