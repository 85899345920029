import React from 'react'

const Loader = ({ type, color="primary" }) => {
  return (
    <div>
        <div class={`spinner-${type} text-${color}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
  )
}

export default Loader