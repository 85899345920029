import React from 'react';
import ConnectWalletComponent from 'Components/ConnectWalet';
import TopNavBar from 'Components/DashboardComponents/TopNavBar';

const ConnectWallet = () => {
  return (
    <React.Fragment>
      <TopNavBar  />
      <ConnectWalletComponent />
    </React.Fragment>
  )
}

export default ConnectWallet