import React from 'react';
import CalculatorComponent from 'Components/DashboardComponents/Calculator';

const Calculator = ({ name }) => {
  return (
    <React.Fragment>
         <div className="col-lg-3"></div>
        <div className="col-lg-6">
            <CalculatorComponent name={name} />
        </div>
        <div className="col-lg-3"></div>
    </React.Fragment>
  )
}

export default Calculator