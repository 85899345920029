const userType = {
    INITIAL_LOGIN_REQUEST: 'INITIAL_LOGIN_REQUEST',

    INITIAL_LOGIN: 'INITIAL_LOGIN',

    VERIFY_LOGIN_REQUEST: 'VERIFY_LOGIN_REQUEST',

    LOOP_REQUEST: 'LOOP_REQUEST',

    LOGIN_USER: 'LOGIN_USER',

    LOGIN_ERROR: 'LOGIN_ERROR',

    SET_USER_ONLINE_STATUS: 'SET_USER_ONLINE_STATUS',

    LOGOUT: 'LOGOUT',

    SET_TRADE_CONTACT: 'SET_TRADE_CONTACT',

    SET_NOTIFICATION: 'SET_NOTIFICATION',

    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',

    GET_PROFILE: 'GET_PROFILE',

    MAKE_USER_ORDERS_REQUEST: 'MAKE_USER_ORDERS_REQUEST',

    MAKE_USER_ORDERS: 'MAKE_USER_ORDERS',

    SOCKET_UPDATE_USER_ORDER: 'SOCKET_UPDATE_USER_ORDER',

    ERROR: 'ERROR',

}

export default userType;
