
   
import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import mainReducer from './mainReducer';
import initialState from './initialState';

export const StateContext = React.createContext();

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const values = { state, dispatch };
  return (
    <StateContext.Provider value={values}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);

StateProvider.propTypes = {
  children: PropTypes.instanceOf(Object)
};

StateProvider.defaultProps = {
  children: {}
};

export default StateProvider;
