import React from 'react';

import WalletInfo from 'Components/DashboardComponents/WalletInfo';
// import TotalCart from 'Components/DashboardComponents/TotalCart';
import Counter from 'Components/DashboardComponents/Counter';
import Table from 'Components/DashboardComponents/Table';

const Dashboard = ({ userInfo }) => {

  const stakingStatus = userInfo?.stakingStatus || {};

  return (
    <React.Fragment>
        <WalletInfo walletData={stakingStatus} />
        <div className="col-xl-5 ">
            <Counter stakingStatus={stakingStatus} />
            {/* <TotalCart /> */}
        </div>
        <div className="col-xl-7">
            <Table stakingOrders={userInfo?.stakingStatus?.stakingOrders}/>
        </div>
    </React.Fragment>
  )
}

export default Dashboard