import userState from './state';
import Type from './type';
import { setToken, clearToken, clearPreLogin } from 'utils/localStorageService';

const userReducer = (user = userState, { payload, type, error }) => {
    switch (type) {
        case Type.INITIAL_LOGIN_REQUEST:
            return { ...user, loadingUserData: true }

        case Type.INITIAL_LOGIN:
            return {
                ...user,
                loadingUserData: false,
                qrCode: payload.qrCode,
                dynamicLink: payload.dynamicLink,
                loginId: payload.loginId,
            }
        case 'RESET':
            return {
                ...user,
                loadingUserData: false,
                qrCode: '',
                dynamicLink: '',
                loginId: '',
            }
        case Type.LOOP_REQUEST:
            return {
                ...user,
                counter: user.counter + 1
            }
        case Type.LOGIN_USER:
            const { accessToken, refreshToken, userInfo } = payload
            setToken(accessToken, refreshToken);
            clearPreLogin()
            return {
                ...user,
                verified: true,
                loggedIn: true,
                userInfo,
            }

        case Type.GET_PROFILE_REQUEST:
            return { ...user, loadingUserData: true };

        case Type.GET_PROFILE:
            return {
                ...user,
                userInfo: {
                    ...user.userInfo,
                    ...payload,
                },
                loadingUserData: false
            }
        case Type.LOGOUT:
            clearToken()
            return {
                ...user,
                verified: false,
                loggedIn: false,
                qrCode: '',
                contactPhone: '',
                dynamicLink: '',
                loginId: '',
            }
        case Type.LOGIN_ERROR:
            return {
                ...user,
                loadingUserData: false,
                error: payload
            }
        case Type.SET_TRADE_CONTACT:
            return {
                ...user,
                showAddTradeContactntNotification: true,
                message: 'Trade contact added successfully!',
            }
        case Type.SET_NOTIFICATION:
            return {
                ...user,
                showAddTradeContactntNotification: payload
            }

        case Type.SET_CURRENT_PAGE:
            return {
                ...user,
                dashboard: {
                    ...user.dashboard,
                    currentPage: payload
                }
            }

        case Type.MAKE_USER_ORDERS_REQUEST:
            return {
                ...user,
                deposit: {
                    ...user.deposit,
                    depositLoading: true
                }
            }
        case Type.MAKE_USER_ORDERS:
            return {
                ...user,
                ...user,
                deposit: {
                    ...user.deposit,
                    depositLoading: false,
                    qrCode: payload.orderDepositQrCode,
                    dynamicLink: payload.orderDepositDeepLink,
                }
            }
        case Type.SOCKET_UPDATE_USER_ORDER:
            return {
                ...user,
                deposit: {
                    ...user.deposit,
                    processed: payload,
                    qrCode: '',
                    dynamicLink: '',
                }
            }
        case Type.ERROR:
            return {
                ...user,
                loadingUserData: false,
                deposit: {
                    ...user.deposit,
                    depositLoading: false,
                },
                error: payload
            }
        case 'CLEAR_ERROR':
            return {
                ...user,
                error: ''
            }
        default:
            return user;
    }
}

export default userReducer;
