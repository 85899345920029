export const getAPR = (value, apr, accObj) => {
    const numValue = Number(value)
    const earlyAccount = accObj.earlyAccount;
    const maximumStakeThreshold = accObj.maximumStakeThreshold;
    const maximumStakePercentageReward = accObj.maximumStakePercentageReward;

    if (earlyAccount) {
        const aprValue = accObj?.percentageRewardForEarlyAccounts / 100;
        return {
            apr: aprValue * numValue || 0,
            aprValue: aprValue || 0,
        }
    }

    if (numValue > maximumStakeThreshold) {
        const aprValue = maximumStakePercentageReward / 100;
        return {
            apr: aprValue * numValue || 0,
            aprValue: aprValue || 0,
        }
    }
    const aprObj = apr?.find(item => numValue >= item.minimumStake && numValue <= item.maximumStake) || {};
    const aprValue = aprObj?.percentageReward / 100;

    return {
        apr: numValue * aprValue || 0,
        aprValue: aprValue || 0,
    }
}
