import { getItem } from 'utils/localStorageService';

const dynamicLink = getItem('staking_dynamic_link');
const qrCode = getItem('staking_qr_code');
const loginId = getItem('staking_login_id');
const loggedIn = getItem('staking_access_token') ? true : false;

const user = {
    dynamicLink,
    qrCode,
    loginId,
    loggedIn,
    accessToken: '',
    refreshToken: '',
    loading: false,
    loadingUserData: false,
    verified: false,
    counter: 0,
    message: '',
    userInfo: {},
    dashboard: {
        totalXBNBalance: 12220,
        availableStakingBalance: 0,
        totalStakingBalance: 0,
        expectedReturnsBalance: 0,
        currentPage: 'dashboard',
    },
    deposit: {
        depositLoading: false,
        qrCode: '',
        dynamicLink: '',
        processed: false,
    },
    error: {}
}

export default user;
