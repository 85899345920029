import React, { useEffect } from 'react';
import './countDown.scss';
const CountDown = ({ date = new Date(new Date().getFullYear(), 11, 31) }) => {

    useEffect(() => {
        const endCountdown = new Date(date);
        const specs = {
            'radius': 50,
            'centerX': 50,
            'centerY': 50,
            'thickness': 10,
            'offset': -Math.PI / 2,
            'color': '#1550a6',
            'bgColor': '#ccc',
            'idFont': 'small-caps 400 10px Verdana',
            'valueFont': 'bold 30px Verdana',
            'fontColor': '#000'
        };
        const time = {
            'millisecond': 1000,
            'second': 60,
            'minute': 60,
            'hour': 24,
            'day': 365,
            // 'month': 12
        }
        const info = {};
        
        // canvas init
        const canvasElements = Array.prototype.slice.call(document.querySelectorAll('canvas'));
        const canvasCtx = [];
        canvasElements.forEach(function (canvas, index) {
            canvas.width = specs.centerX * 2;
            canvas.height = specs.centerY * 2;
            canvasCtx[index] = canvas.getContext('2d');
            const name = canvas.id;
            info[name] = { 'ctx': index, 'value': 0, 'prevValue': -1 };
        });
        const canvasKeys = Object.keys(info);
        // info.month.denominator = time.month;
        info.days.denominator = time.day;
        info.hours.denominator = time.hour;
        info.minutes.denominator = time.minute;
        info.seconds.denominator = time.second;

        // show remaining time
        function showRemainingTime() {
            const now = new Date();
            // calculate new values
            const secondsLeft = Math.max(0, Math.floor((endCountdown - now) / 1000));
            // info.month.value = Math.floor(secondsLeft / (time.second * time.minute * time.hour));
            info.days.value = Math.floor(secondsLeft / (time.second * time.minute * time.hour));
            info.hours.value = Math.floor((secondsLeft % (time.second * time.minute * time.hour)) / (time.second * time.minute));
            info.minutes.value = Math.floor((secondsLeft % (time.second * time.minute)) / time.second);
            info.seconds.value = Math.floor(secondsLeft % time.second);
        
            // update changed values only
            canvasKeys.forEach(function (key) {
                if (info[key].value !== info[key].prevValue) {
                    if (key === 'days' && info[key].value > 365) {
                        // exception if days is more than 1 year
                        draw(canvasCtx[info[key].ctx], 1, key, info[key].value);
                    } else {
                        draw(canvasCtx[info[key].ctx], info[key].value / info[key].denominator, key, info[key].value);
                    }
                    info[key].prevValue = info[key].value;
                }
            });
        }
        
        // draw function
        function draw(ctx, part, id, value) {
            // calculate angles
            const start = specs.offset;
            const between = 2 * Math.PI * part + specs.offset;
            const end = 2 * Math.PI + specs.offset;
        
            // clear canvas
            ctx.clearRect(0, 0, specs.centerX * 2, specs.centerY * 2);
        
            // draw remaining %
            ctx.fillStyle = specs.color;
            ctx.beginPath();
            ctx.arc(specs.centerX, specs.centerY, specs.radius, start, between);
            ctx.arc(specs.centerX, specs.centerY, specs.radius - specs.thickness, between, start, true);
            ctx.closePath();
            ctx.fill();
        
            // draw bg
            ctx.fillStyle = specs.bgColor;
            ctx.beginPath();
            ctx.arc(specs.centerX, specs.centerY, specs.radius, between, end);
            ctx.arc(specs.centerX, specs.centerY, specs.radius - specs.thickness, end, between, true);
            ctx.closePath();
            ctx.fill();
        
            // draw text
            ctx.fillStyle = specs.fontColor;
            ctx.font = specs.idFont;
            ctx.fillText(id, specs.radius - ctx.measureText(id).width / 2, specs.thickness * 3);
            ctx.font = specs.valueFont;
            ctx.fillText(value, specs.radius - ctx.measureText(value).width / 2, specs.radius * 2 - specs.thickness * 3);
        }
    
        // change countdown every second
        setInterval(showRemainingTime, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

  return (
    <React.Fragment>
        <div id="countdown">
            {/* <p><strong>Countdown to date</strong></p> */}
            <div className="canvas-container" >
                {/* <canvas id="months"></canvas> */}
                <canvas id="days"></canvas>
                <canvas id="hours"></canvas>
                <canvas id="minutes"></canvas>
                <canvas id="seconds"></canvas>
            </div>
        </div>
    </React.Fragment>
  )
}

export default CountDown
