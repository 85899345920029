/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useStateValue } from 'providers/stateProvider';

// import { ShowConfirm } from 'Components/Shared/Notification';


import bantuLogoOrange from 'assets/bantuLogoOrange.png'

import styles from './dashboard.module.scss'

const Navigation = () => {
    const [{ user: { dashboard: { currentPage }} }, dispatch] = Object.values(useStateValue());
    const navigate = useNavigate()

    const selectPage = (page) => dispatch({
        type: 'SET_CURRENT_PAGE',
        payload: page
    })

    // const logout = () => {
    //     ShowConfirm('Are you sure?', 'warning', '', 'Logout', 'No').then(result => { 
    //         if (result.value) {
    //            navigate('/logout');
    //         }
    //     })
    // }
    return (
    <React.Fragment>
         <nav className="sidebar"style={{boxShadow: '0 0 0px #eff1f7'}} >
                {/* <div className="logo d-flex justify-content-between" style={{ margin: '0px', background: '#fff', padding: '12px 16px', borderRadius: '0px'}}>
                    <div className="sidebar_close_icon d-lg-none">
                        <i className="ti-close"></i>
                    </div>

                    <a to='/'><img style={{width: '50px', paddingBottom: "14px"}} src={bantuLogoOrange} alt="" /></a>
                    <a to='/'><span className='xbn-text' style={{width: '188px', fontSize: '30px'}}>Harambee</span></a>
                </div> */}
                <div className="logo d-flex justify-content-between" style={{ paddingLeft: '0'}}>
                    <Link to='/'><img style={{width: '50px', paddingBottom: "14px"}} src={bantuLogoOrange} alt="" /></Link>
                    <Link to='/'><span className='xbn-text' style={{ fontSize: '24px'}}>Harambee</span></Link>
                    <div className="sidebar_close_icon d-lg-none">
                        <i className="ti-close"></i>
                    </div>
                </div>
                <ul id="sidebar_menu">
                    {/* <li className="side_menu_title">
                        <span>Dashboard</span>
                    </li> */}
                    <li className="side_menu_title mm-active side_menu_title">
                        <div>
                            <span>Dashboard</span>
                        </div>
                        <ul>
                            <li>
                                <div onClick={() => selectPage('dashboard')} className={`${currentPage === 'dashboard' ? styles.activeLink: ''} ${styles.link}`} >
                                    <img src="assets/img/menu-icon/1.svg" alt="" />
                                    Home
                                </div>
                            </li>
                            <li>
                                <div onClick={() => selectPage('stake')} className={`${currentPage === 'stake' ? styles.activeLink: ''} ${styles.link}`} >
                                    <img src="assets/img/menu-icon/7.svg" alt="" />
                                    Stake
                                </div>
                            </li>
                            <li>
                                <div onClick={() => selectPage('history')} className={`${currentPage === 'history' ? styles.activeLink: ''} ${styles.link}`} >
                                    <img src="assets/img/menu-icon/2.svg" alt="" />
                                    History
                                </div>
                            </li>
                            {/* <li>
                                <div onClick={() => logout()} className={`${styles.link}`} >
                                <i className="ti-shift-left"></i>
                                    Logout
                                </div>
                            </li> */}
                            {/* <li><div onClick={() => selectPage('calculator')} className={`${currentPage === 'calculator' ? styles.activeLink: ''} ${styles.link}`} >Calculator</div></li> */}
                        </ul>
                    </li>
                    <li className="side_menu_title mm-active side_menu_title">
                        <a  className={`${currentPage === 'dashboard' ? styles.activeLink: ''} ${styles.link}`} aria-expanded="false">
                            {/* <img src="assets/img/menu-icon/1.svg" alt="" /> */}
                            {/* <span>Wallet</span> */}
                        </a>
                        <ul>
                            <li>
                            <div onClick={() => navigate('/logout')} className={`${styles.link}`} >
                                {/* <i className="ti-shift-left"></i> */}
                                    Logout
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
    </React.Fragment>
  )
}

export default Navigation