import React from 'react'
import Carousel from 'react-multi-carousel';

import Fmfw from 'assets/fmfw.png'
import Bittrex from 'assets/bittrex.png'
import Bitmart from 'assets/bitmart.png'
import TradeFada from 'assets/tradefadaLogo.png'
import Roqqu from 'assets/roqqu.png'

import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const BuyXBN = () => {
  return (
    <React.Fragment>
         <div className="container mt-3 mb-3 py-2 text-center">
        <div className="row">
        <Carousel responsive={ responsive } showDots={ false } autoPlay={ true } infinite={ true } arrows={ false } containerClass="carousel-container" >
          <div className="col-md-10 mb-3">
            <div className="card" style={{ borderRadius: "16px" }}>
              <div className="card-body">
                {/* <h5 className="card-title crd-title">GET XBN ON</h5> */}
                <img style={{ width: '156px', fontSize: '29px', color: '#5295CC', paddingBottom: '20px', paddingTop: "20px" }} src={Bitmart} alt='bitmart' />
                <div>
                  <a target="_blank" rel="noreferrer" style={{ width: '206px', fontSize: '17px'  }} href="https://www.bitmart.com/trade/en?symbol=XBN_USDT&layout=basic" className="btn btn-primary btn-lg">Get XBN for Harambee
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 mb-3">
            <div className="card" style={{ borderRadius: "16px" }}>
              <div className="card-body">
                {/* <h5 className="card-title crd-title">GET XBN ON</h5> */}
                <img style={{ width: '156px', fontSize: '29px', color: '#5295CC', paddingBottom: '30px', paddingTop: "20px" }} src={Fmfw} alt='Fmfw.io' />
                <div><a target="_blank" rel="noreferrer" href="https://fmfw.io/xbn-to-usdt" style={{ width: '206px', fontSize: '17px'  }} className="btn btn-primary">Get XBN for Harambee
                </a></div>
              </div>
            </div>
          </div>
          <div className="col-md-10 mb-3">
            <div className="card" style={{ borderRadius: "16px" }}>
              <div className="card-body">

                {/* <h5 className="card-title crd-title ">GET XBN ON</h5> */}
                <img style={{ width: '156px', fontSize: '29px', color: '#5295CC', paddingBottom: '25px', paddingTop: "20px" }} src={Bittrex} alt='bittrex' />
                <div><a target="_blank" rel="noreferrer" href="https://global.bittrex.com/Market/Index?MarketName=USDT-XBN" style={{ width: '206px', fontSize: '17px'  }} className="btn btn-primary">Get XBN for Harambee
                </a></div>
              </div>
            </div>
          </div>
          <div className="col-md-10 mb-3 ">
            <div className="card" style={{ borderRadius: "16px" }}>
              <div className="card-body">
                {/* <h5 className="card-title crd-title">GET XBN ON</h5> */}
                <img style={{ width: '156px', fontSize: '29px', color: '#5295CC', paddingBottom: '35px', paddingTop: "20px" }} src={TradeFada} alt='tradefada' />
                <div><a target="_blank" rel="noreferrer" href="https://exchange.tradefada.com/xbn-to-usdt" style={{ width: '206px', fontSize: '17px'  }} className="btn btn-primary">Get XBN for Harambee
                </a></div>
              </div>
            </div>
          </div>
          <div className="col-md-10 mb-3 ">
            <div className="card" style={{ borderRadius: "16px" }}>
              <div className="card-body">
                {/* <h5 className="card-title crd-title">GET XBN ON</h5> */}
                <img style={{ width: '156px', fontSize: '29px', color: '#5295CC', paddingBottom: '25px', paddingTop: "20px" }} src={Roqqu} alt='roqqu' />
                <div><a target="_blank" rel="noreferrer" href="https://exchange.tradefada.com/xbn-to-usdt" style={{ width: '206px', fontSize: '17px'  }} className="btn btn-primary">Get XBN for Harambee
                </a></div>
              </div>
            </div>
          </div>
           </Carousel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BuyXBN