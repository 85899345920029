
const setItem = (key, value) => localStorage.setItem(key, value);

const getItem = (key) => localStorage.getItem(key);

const removeItem = (key) => localStorage.removeItem(key);

const setToken = (accessToken, refreshToken) => {
    setItem('staking_access_token', accessToken);
    setItem('staking_refresh_token', refreshToken);
};

const clearToken = () => {
    removeItem('staking_access_token');
    removeItem('staking_refresh_token');
    removeItem('staking_username');
}

const getAccessToken = () => getItem('staking_access_token');

const getRefreshToken = () => getItem('staking_refresh_token');

const getLoginId = () => getItem('staking_login_id');

const setPreLogin = (dynamicLink, qrCode, loginId) => {
    setItem('staking_login_id', loginId);
    setItem('staking_dynamic_link', dynamicLink);
    setItem('staking_qr_code', qrCode);
}

const clearPreLogin = () => {
    removeItem('staking_login_id');
    removeItem('staking_dynamic_link');
    removeItem('staking_qr_code');
}

export {
    setToken,
    getAccessToken,
    clearToken,
    getRefreshToken,
    setItem,
    getItem,
    removeItem,
    setPreLogin,
    clearPreLogin,
    getLoginId,
};
