import axios from 'axios';
import customAxios from './customAxios';

const baseURL = `https://${process.env.REACT_APP_URL}`;

const loginRequest = (data) => axios.post(`${baseURL}/v1/login`, data);

const verifyLoginRequest = (data) => axios.get(`${baseURL}/v1/users/verify/${data.username}/${data.loginId}`);

const getUserDetailsRequest = (data) => customAxios.get(`${baseURL}/v1/users/detail/${data.username}`);

const makeUserOrdersRequest = (data) => customAxios.post(`${baseURL}/v1/users/orders`, data);

export {
    loginRequest,
    verifyLoginRequest,
    getUserDetailsRequest,
    makeUserOrdersRequest,
}
