import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setItem } from "utils/localStorageService";
import { userLogin, verifyLogin } from "providers/action/user";
import { useStateValue } from "providers/stateProvider";
import { clearPreLogin, clearToken } from "utils/localStorageService";
import Loader from "Components/Shared/Loader";
import { notification } from "Components/Shared/Notification";


const ConnectWallet = () => {
  const [username, setUsername] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [{ user }, dispatch] = Object.values(useStateValue());
  const { loadingUserData, dynamicLink, loginId, qrCode, verified, counter } = user;

  useEffect(() => {
    if (loginId) {
      let user_name;
  
      if (!username.includes("@")) {
        user_name = `${username?.trim()}@bantupay`;
      } else {
        user_name = username?.trim();
      }

      setPage(2);
      let timer;

      const data = {
        loginId,
        username: user_name,
      };
      timer = setTimeout(async () => {
        await verifyLogin(data, dispatch);
      }, 5000);

      if (verified) {
        clearTimeout(timer);
        // const from = getItem('from')
        notification("Wallet connection established");
        navigate("/dashboard");
      }
    }
  }, [loginId, username, dispatch, verified, counter, navigate]);

  const submitForm = () => {
    let user_name;

    if (!username.includes("@")) {
      user_name = `${username?.trim()}@bantupay`?.trim();
    } else {
      user_name = username?.trim();
    }

    setItem("staking_username", user_name);
    userLogin({ username: user_name }, dispatch);
  };

  const reset = () => {
    clearPreLogin();
    clearToken();
    setPage(1);
    dispatch({ type: "RESET" });
  };

  const resetLogin = () => (
    <p
      onClick={reset}
      style={{
        cursor: "pointer",
        textAlign: "center",
        color: "#1550a6",
        fontSize: "16px",
      }}
    >
      Reset Login
    </p>
  );

  const loginForm = () => (
    <div className="modal-body">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="username"
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your BantuPay username"
          />
        </div>
        <button
          href="#"
          onClick={submitForm}
          className="btn btn-primary"
          disabled={loadingUserData}
        >
          {" "}
          {loadingUserData ? <Loader type="border" color="secondary" /> : "Connect"}{" "}
        </button>
      </form>
    </div>
  );

  const QRCode = () => (
    <React.Fragment>
      <img
        style={{ width: "70%", margin: "0 auto" }}
        src={qrCode}
        alt="bullet point"
      />
      <br />
      <br />
      <p
        style={{
          cursor: "pointer",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        Or
      </p>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <button
          className="btn btn-lg btn-primary"
          onClick={() => window.open(dynamicLink, "_blank")}
          
        >
          Authorize with BantuPay
        </button>
      </div>
      <br />
      <br />
      {resetLogin()}
      <br />
      <br />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="white_box mb_30">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="modal-content cs_modal">
                    <div style={{justifyContent: 'center'}} className="modal-header">
                      <h5 className="modal-title">
                        Connect to BantuPay wallet
                      </h5>
                    </div>

                    {page === 1 ? loginForm() : QRCode()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConnectWallet;
