import React from "react";
import one from 'assets/1.png';
import two from 'assets/2.png';
import three from 'assets/3.png';

const StakeInstructions = () => {
  return (
    <React.Fragment>
      <div className="content" style={{ fontSize: '16px'}}>
      <p className="py-3">
      How it works:
        </p>
        <div className="m-top-25">
          <ul className="check-list--one" style={{ fontSize: '16px'}}>
            <div className=" row list-item checklist icon-secondary pb-3">
            <div className="col-lg-1" ><img src={one} alt='' style={{
            width: "40px", paddingRight: '10px'}} /></div>
              <div className="col" >
                Go to either <a href='https://www.bitmart.com/trade/en?symbol=XBN_USDT&layout=basic' style={{color: '#007bff'}} rel="noreferrer" target="_blank">Bitmart</a>,
                <a href='https://fmfw.io/xbn-to-usdt' rel="noreferrer" style={{color: '#007bff'}} target="_blank"> FMFW.io</a>,
                <a href='https://global.bittrex.com/Market/Index?MarketName=USDT-XBN' rel="noreferrer" style={{color: '#007bff'}} target="_blank"> Bittrex Global</a> and 
                <a href='https://exchange.tradefada.com/xbn-to-usdt' style={{color: '#007bff'}} rel="noreferrer" target="_blank"> Tradefada </a>  
                to acquire the XBN you wish to stake and send them to your BantuPay wallet</div>
            </div>
            <div className="row list-item checklist icon-secondary pb-3">
            <div className="col-lg-1" ><img src={two} alt='' style={{
            width: "40px", paddingRight: '10px'}} /></div>
              <div className="col" >Enter the amount of XBN you wish to stake (for 6 months) in the Staking Section. You will see your expected returns automatically calculated.</div>
            </div>
            <div className=" row list-item checklist icon-secondary pb-3">
            <div className="col-lg-1" ><img src={three} alt='' style={{
            width: "40px", paddingRight: '10px'}} /></div>
              <div className="col" >Click the button to deposit XBN for staking, and authorize the transaction.</div>
            </div>
          </ul>
          <p className="py-3">
          You will have to wait till the 6 months staking period is over before you are able to withdraw your staked tokens and earned tokens to your wallet!
        </p>
          <p className="py-3">
          It’s really that simple.
        </p>
          <p className="py-3">
          <b>Important Information:</b>
        </p>
        <ul className="check-list--one" style={{ fontSize: '16px'}}>
            <li className="list-item checklist icon-secondary">
              -  This opportunity closes on 31st March, 2022.
              </li>
            <li className="list-item checklist icon-secondary">
              -  XBN staked cannot be withdrawn until the staking period is over.
              </li>
            <li className="list-item checklist icon-secondary">
              -  Accounts created before 1st March will only be able to earn 20% APR on their staked tokens
              </li>
            <li className="list-item checklist icon-secondary">
              -  Only the amount of XBN acquired from the approved exchanges will be eligible and approved for staking.
              </li>
          </ul>
          <div className="py-5">
          If you need any assistance, please visit <a href="https://t.me/banturewards" target="_blank" rel="noreferrer" style={{color: '#007bff'}}>t.me/banturewards</a>. Our team will be happy to guide you through.
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StakeInstructions;
