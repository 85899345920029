import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import bantuLogoWhite from 'assets/bantuLogoWhite.png'

const Footer = () => {
  return (
    <React.Fragment>
        <div className='footer_part' style={{ position: 'inherit',  background: '#ffffff', paddingBottom: '20px' }}>
        {/* <div className="container"> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className='footer_iner' >
                        <div className="py-2 text-center" style={{ padding: '40px', background: '#11223C' }}>
        <div className="row" style={{ color: '#ffffff', padding: '20px 0' }}>
          <div className="col-sm">
            <div className='py-3 pb-5'>
              <img src={bantuLogoWhite} alt='' style={{ color: '#fff', width: "49.96px", position: 'absolute' }} /> <span className='xbn-text-footer ms-5'>Harambee</span>
            </div>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Legal & Privacy</div>
            <div><Link style={{ color: '#ffffff'}} to="/terms-and-conditions">Terms of Use</Link></div>
            <a style={{ color: '#ffffff'}} target="_blank" rel="noreferrer" href="https://bantufoundation.org/privacy/">Privacy Policy</a>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Support</div>
            <div>User Guide
            </div>
            <div>User Support</div>
          </div>
          <div className="col-sm text-center" style={{ color: '#ffffff', padding: '20px 0' }}>
            <div className='foot-text'>Community</div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://t.me/bantublockchain" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faTelegram} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://web.facebook.com/bantublockchain?_rdc=1&_rdr" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faFacebook} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/bantublockchain" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faTwitter} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/bantublockchain/mycompany/" ><span style={{ fontSize: '17px', color: '#5295CC' }}><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '29px', paddingLeft: '20px', color: '#ffffff' }} /></span></a>

            </div>
          </div>
        </div>


      </div>
                            <p>{new Date().getFullYear} © BantuPay - Designed by <a href="/"> <i className="ti-heart"></i> </a><a href="/">Bantu Blockchain Foundation</a></p>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
    </React.Fragment>
  )
}

export default Footer
