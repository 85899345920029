import React from "react";
import Calculator from "Components/DashboardComponents/Calculator";
import StakeInstructions from "Components/DashboardComponents/StakeInstructions";
import { formatNum } from "Components/utils/formatNumbers";

const Stake = ({ userInfo }) => {
  const stakingAPR = userInfo?.stakingStatus?.stakingProgram?.stakingAPR;
  const compare = (a, b) => {
    if (a.percentageReward < b.percentageReward) {
      return -1;
    }
    if (a.percentageReward > b.percentageReward) {
      return 1;
    }
    return 0;
  };

  return (
    <React.Fragment>
      <div className="col-xl-6">
        <div className="white_box QA_section card_height_100">
          <p>
            <div className="col-md-12">
              <h2>Percentage returns </h2>
              {!userInfo.earlyAccount ? (
                <table className="table lms_table_active2">
                  <thead>
                    <tr>
                      <td>Min Amount (XBN)</td>
                      <td>Max Amount (XBN)</td>
                      <td>Reward</td>
                    </tr>
                  </thead>
                  <tbody>
                    {stakingAPR?.sort(compare)?.map((item, index) => (
                      <tr>
                        <td>{formatNum(item.minimumStake)}</td>
                        <td>{formatNum(item.maximumStake)}</td>
                        <td>{item.percentageReward}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>
                  <p>
                    {
                      userInfo?.stakingStatus?.stakingProgram
                        ?.percentageRewardForEarlyAccounts
                    }{" "}
                    %
                  </p>
                  <br />
                </div>
              )}
            </div>
            <div className="col-xl-12">
              <h2>Instructions</h2>
              <StakeInstructions />
            </div>
          </p>
        </div>
      </div>
      <div className="col-xl-6">
        <Calculator name={userInfo?.stakingStatus?.stakingProgram?.program} />
      </div>
    </React.Fragment>
  );
};

export default Stake;
