import React from 'react';
import TopNavBar from 'Components/DashboardComponents/TopNavBar';
import TermsAndConditionComponent from 'Components/TermsAndCondition';

const TermsAndCondition = () => {
  return (
    <React.Fragment>
    <div  style={{ background: '#ffffff' }}>
      <TopNavBar/>
      <TermsAndConditionComponent />
    </div>
  </React.Fragment>

  )
}

export default TermsAndCondition;