import React from "react";
import { Link } from "react-router-dom";
import bantuLogoOrange from "assets/bantuLogoOrange.png";
import ErrorNotification from '../Shared/ErrorNotification'

const TopNavBar = ({ userData }) => {
  return (
    <React.Fragment>
      <ErrorNotification />
      <div className="container-fluid no-gutters">
        <div className="row">
          <div className="col-lg p-0">
            <div className="header_iner d-flex justify-content-between align-items-center">
              <div className="sidebar_icon d-lg-none">
                <i className="ti-menu"></i>
              </div>
              <div className="serach_field-area">
                 <div
                  className=" col-lg logo d-flex justify-content-between"
                  style={{
                    margin: "0px",
                    background: "#fff",
                    padding: "12px 16px",
                    borderRadius: "0px",
                    height: "81px",
                  }}
                >
                  <Link to="/">
                    <img
                      style={{ width: "50px", paddingBottom: "14px" }}
                      src={bantuLogoOrange}
                      alt=""
                    />
                    <span
                      className="xbn-text"
                      style={{ width: "188px", fontSize: "30px" }}
                    >
                      Harambee
                    </span>
                  </Link>
                </div>  
              </div>

              <div className="header_right d-flex justify-content-between align-items-center">
                <div className="profile_info">
                  {userData?.imageThumbnail && (
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      src={userData?.imageThumbnail}
                      alt="#"
                    />
                  )}
                  <div className="profile_info_iner">
                    <p style={{ color: "white" }}>
                      {userData?.firstName} {userData?.lastName}{" "}
                    </p>
                    <h5>{userData?.username}</h5>
                    <div className="profile_info_details">
                      {/* <a href="/">My Profile <i className="ti-user"></i></a> */}
                      {/* <a href="/">Settings <i className="ti-settings"></i></a> */}
                      {/* <Link to="/logout">
                        Log Out <i className="ti-shift-left"></i>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopNavBar;
