import Routes from './Routes';
import './App.css';
import useImportScript from 'Components/utils/importScript';

function App() {
  useImportScript('assets/js/custom.js');
  return (
    <Routes />
  );
}

export default App;
