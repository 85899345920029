import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Modal, Button } from 'react-bootstrap';
import BuyXBN from 'Components/HomeComponent/BuyXBN';
import Loading from 'Components/Shared/Loader';

import { formatNum } from 'Components/utils/formatNumbers';
import { getAPR } from 'utils/calculator';
import { useStateValue } from 'providers/stateProvider';
import { makeUserOrders, getUserDetails } from 'providers/action/user'

import styles from './dashboard.module.scss'

const Calculator = ({ name }) => {

    const [state, setState] = useState({ deposit: '', cumulativeDeposit: 0 });
    const [aprResult, setAprResult] = useState({ aprValue: 0, aprResult: 0 });
    const [error, setError] = useState({ error: false, errorMessage: '', info: false });
    const [disabled, setDisabled] = useState(true);
    const [minDepAmt, setMinDepAmt] = useState(0);
    const [availableToStakeBal, setAvailableToStakeBal] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [asset, setAsset] = useState('XBN');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [
        { user: { userInfo: { stakingStatus, earlyAccount }, dashboard: { currentPage }, deposit } },
        dispatch] = Object.values(useStateValue());

    const apr = stakingStatus?.stakingProgram?.stakingAPR;

    useEffect(() => {
        if (earlyAccount) {
            setMinDepAmt(stakingStatus?.stakingProgram?.minimumStakeForEarlyAccounts);
        } else {
            setMinDepAmt(stakingStatus?.stakingProgram?.minimumStake);
        }

        setState({...state, cumulativeDeposit: stakingStatus?.totalStaked})
        setAvailableToStakeBal(stakingStatus?.availableToStake);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const updateDashboard = async () => {
            await getUserDetails(dispatch, true);
        }
        if (deposit.processed) {
            updateDashboard();
        }
    }, [deposit.processed, dispatch]);

    useEffect(() => {
        const accObj = {
            earlyAccount,
            percentageRewardForEarlyAccounts: stakingStatus?.stakingProgram?.percentageRewardForEarlyAccounts,
            maximumStakeThreshold: stakingStatus?.stakingProgram?.maximumStakeThreshold,
            maximumStakePercentageReward: stakingStatus?.stakingProgram?.maximumStakePercentageReward,
        }
        setAprResult({ ...aprResult, ...getAPR(state.cumulativeDeposit, apr || [], accObj) });
        const availableStakeBal = stakingStatus?.availableToStake - state.deposit;
        setAvailableToStakeBal(availableStakeBal < 0 ? 0 : availableStakeBal);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.cumulativeDeposit, apr])

    useEffect(() => {
        const { deposit, cumulativeDeposit } = state;
        const totalStaked = stakingStatus?.totalStaked
        const availableToStake = stakingStatus?.availableToStake;

        if (availableToStake < minDepAmt && totalStaked < minDepAmt) {
            setError({ error: true, errorMessage: `Your "available to stake balance (${availableToStake} ${asset})" is not enough ${asset} to deposit`, info: true });
        } else {
            if (cumulativeDeposit >= minDepAmt) {
                setDisabled(true);

                if (deposit > availableToStake) {
                    setError({ error: true, errorMessage: `You can't stake more than ${formatNum(availableToStake)}${asset}`, info: true });
                } else {
                    setDisabled(false);
                    setError({ error: false, errorMessage: '', info: false });
                }
            } else {
                setDisabled(true);
                setError({ error: true, errorMessage: `Minimum deposit amount is ${formatNum(minDepAmt)}` });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const handleChange = (e) => {
        const value =  e.target.value;
        const availableStakeBal = stakingStatus?.availableToStake - value;
        setAvailableToStakeBal(availableStakeBal < 0 ? 0 : availableStakeBal);
        setState({
            ...state,
            deposit: value,
            cumulativeDeposit: Number(value) + Number(stakingStatus?.totalStaked)
        });
    }


    const depositAll = () => {
        if (availableToStakeBal > 0) {
            const all =  stakingStatus?.availableToStake;
            setState({
                ...state,
                deposit: all,
                cumulativeDeposit: Number(all) + Number(stakingStatus?.totalStaked)
            });
        }
    }

    const processDeposit = async () => {
        await makeUserOrders({ orderAmount: Number(state.deposit) }, dispatch);
        setState({ deposit: 0, cumulativeDeposit: stakingStatus?.totalStaked });
        setAprResult({ aprValue: 0, aprResult: 0 });
        setError({ error: false, errorMessage: '', info: false });
    }

    const resetDeposit = () => {
        dispatch({
            type: 'SOCKET_UPDATE_USER_ORDER',
            payload: false
        })
    }

    const resetLogin = () => (
        <>
            <h2 className="text-center">Scan with BantuPay</h2>
        </>
    )
    const QRCode = () => (
        <React.Fragment>
            <br />
            {resetLogin()}
            <br />
            <img style={{ width: '85%', margin: '0 auto' }} src={deposit.qrCode} alt="bullet point" />
            <br />
            <br />
            <p style={{ cursor: 'pointer', textAlign: 'center', fontSize: '20px', fontWeight: '600' }}>Or</p>
            <br />
            <br />

            <div style={{ textAlign: 'center' }}>
                <Button onClick={() => window.open(deposit.dynamicLink, '_blank')} className={styles.loginbtn}>
                    Authorize with BantuPay
                </Button>
            </div>

            <br />
            <p onClick={resetDeposit} style={{ cursor: 'pointer', textAlign: 'center', color: '#1550a6', fontSize: '16px' }}>Reset Staking Amount</p>

            <br />
            <br />
        </React.Fragment>
    )

    const DepositSuccess = () => (
        <React.Fragment>
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h3>Deposit successful!</h3>
                <Button onClick={resetDeposit} >Deposit again</Button>
                <br />
                <br />
            </div>
        </React.Fragment>
    )

    const DepositOver = () => (
        <React.Fragment>
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h3>This staking program is no longer accepting deposits</h3>
                <p>Watch out for the next program</p>
            </div>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <div className="white_box QA_section card_height_100">
                <div className="container-fluid p-0">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box mb_30">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <h2>{name}</h2>
                                        <p>{stakingStatus?.stakingProgram?.description}</p>
                                        <hr />

                                        <div className="modal-content cs_modal">
                                            {moment() > moment(stakingStatus?.stakingProgram?.holdStartDate) ? <DepositOver /> : deposit.qrCode !== '' ? <QRCode /> :
                                                deposit.processed ? <DepositSuccess /> :

                                                    <div className="modal-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <h4 className="pb-3" style={{ textAlign: 'center' }} >Stake Your Tokens</h4>

                                                                <h3 style={{ color: `${availableToStakeBal < 1 ? 'red' : '#007bfe' }`}} >Available staking balance: {formatNum(availableToStakeBal)}</h3>

                                                                <span style={{ color: '#958f8f' }}>Minimum deposit amount: {formatNum(minDepAmt)} </span>
                                                                <input
                                                                    type="text"
                                                                    name='deposit'
                                                                    style={{ border: '1px solid #c0bdbd' }}
                                                                    className={`form-control ${error.error ? styles.input_error : ''}`}
                                                                    placeholder="Enter XBN amount"
                                                                    onChange={handleChange}
                                                                    value={state.deposit}
                                                                />
                                                                <div style={{ textAlign: 'right', textDecoration: 'underline', color: '#007bfd', cursor: 'pointer'}}><span onClick={depositAll}>Deposit all</span> </div>
                                                                {error.error &&
                                                                    <p className={styles.calculator_error}>{error.errorMessage}
                                                                        {/* {error.info && <span>  - get {asset} <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleShow}>Here</span> </span>}  */}
                                                                    </p>
                                                                }
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-12">
                                                                    <h3>Deposit amount </h3>
                                                                    <p style={{ textAlign: 'left' }}>{formatNum(state.deposit)}</p>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-12">
                                                                    <h3>Cumulative Deposit amount </h3>
                                                                    <p style={{ textAlign: 'left' }}>{formatNum(state.cumulativeDeposit)}</p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-5">
                                                                    <h3>APR </h3>
                                                                    <p style={{ textAlign: 'left' }}> {(aprResult?.aprValue * 100) * 2}% ({aprResult?.aprValue * 100 || 0}% Returns)</p>
                                                                </div>

                                                                <div className="col-lg-7">
                                                                    <h3>Cumulative Expected Returns </h3>
                                                                    <p style={{ textAlign: 'left' }}>{formatNum(aprResult?.apr)}</p>
                                                                </div>
                                                            </div>
                                                            {currentPage === 'stake' &&
                                                                <Button loading disabled={error.error || disabled} onClick={processDeposit || deposit.depositLoading} className="btn_1 full_width text-center">
                                                                    {deposit.depositLoading && <Loading type='border' />}  Deposit XBN for staking
                                                                </Button>}
                                                        </form>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Get More XBN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BuyXBN />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Calculator