import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useStateValue }  from 'providers/stateProvider';
// import notification from '../Components/Shared/Notification';

const Logout = () => {
    const navigate  = useNavigate();
    const [,dispatch] = Object.values(useStateValue());

    useEffect(() => {
    dispatch({
        type: 'LOGOUT'
      });
        // notification('warning', t('Please login to continue'));
        navigate('/connect-wallet');
    }, [dispatch, navigate]);
    return (
        <React.Fragment>hiii</React.Fragment>
    )
}

export default Logout
