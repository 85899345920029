import React, { useEffect } from 'react'
import { useStateValue } from 'providers/stateProvider';
import { ShowConfirm } from './Notification';

const NotifyCtrl = () => {
    const [{ user: {  error }}, dispatch] = Object.values(useStateValue());

    useEffect(() => {
      if (Object.keys(error || {}).length > 0) {
        ShowConfirm('Error', 'error', error, 'Okay');
        dispatch({ type: 'CLEAR_ERROR' });
      }
    }, [error, dispatch]);
  return (
    <React.Fragment></React.Fragment>
  )
}

export default NotifyCtrl