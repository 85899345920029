import React from 'react';
import moment from 'moment';
import CountDown from './CountDown';

const Counter = ({ stakingStatus }) => {
    const date = stakingStatus?.stakingProgram?.holdEndDate
    const holdStartDate = stakingStatus?.stakingProgram?.holdStartDate
    const time = moment(holdStartDate).format('YYYY-MM-DD HH:mm:ss');
    const now = new Date();
    const isAfter = moment(now).isAfter(time);

    return (
        <React.Fragment>
            <div className="white_box mb_30">
                <div className="box_header border_bottom_1px  ">
                    <div className="main-title">
                        <h3 className="mb_25">{isAfter ? 'Staking days left': 'Deposit days left'}</h3>
                    </div>
                </div>
                <CountDown date={isAfter ? date : holdStartDate} />
            </div>
        </React.Fragment>
    )
}

export default Counter
