import React from 'react';
import { useStateValue } from 'providers/stateProvider';

import { formatNum } from 'Components/utils/formatNumbers';

const WalletInfo = ({ walletData }) => {
    const [, dispatch ] = Object.values(useStateValue());

    const {
        availableToStake,
        totalStaked,
        userWalletBalance,
        totalExpectedReturn,
        totalStakedInUSDT,
        totalExpectedReturnInUSDT,
        availableToStakeInUSDT,
        userWalletBalanceInUSDT
    } = walletData;

  return (
    <React.Fragment>
        {/* <div className="col-lg-12"> */}
            <div className="single_element">
                <div className="quick_activity">
                    <div className="row">
                        <div className="col-12">
                            <div className="quick_activity_wrap">
                                <div className="single_quick_activity d-flex" style={{border: '1px solid #ddd', textAlign: 'center'}}>
                                    {/* <div className="icon">
                                        {/* <img src={Vector} alt="" /> */}
                                    {/* </div>  */}
                                    <div className="">
                                        <h3><span className="counter-">{formatNum(userWalletBalance)}</span> </h3>
                                        <p style={{color: '#28a745', fontSize: '28px'}}>$ {formatNum(userWalletBalanceInUSDT)}</p>
                                        <div style={{color: '#6c757da6', fontSize: '18px'}}>Total XBN Balance</div>
                                    </div>
                                </div>
                                <div className="single_quick_activity d-flex" style={{border: '1px solid #ddd', textAlign: 'center'}}>
                                    {/* <div className="icon"> */}
                                        {/* <img src={Vector} alt="" /> */}
                                    {/* </div> */}
                                    <div className="">
                                        <h3><span className="counter-">{formatNum(availableToStake)}</span> </h3>
                                        <p style={{color: '#28a745', fontSize: '28px'}}>$ {formatNum(availableToStakeInUSDT)}</p>
                                        <div style={{color: '#6c757da6', fontSize: '18px'}}>Available staking balance</div>
                                        {/* <span>{formatNum(totalBoughtFromExchanges)}</span> */}
                                    </div>
                                </div>
                                <div className="single_quick_activity d-flex" style={{border: '1px solid #ddd', textAlign: 'center'}}>
                                    {/* <div className="icon"> */}
                                        {/* <img src={Vector} alt="" /> */}
                                    {/* </div> */}
                                    <div className="">
                                        <h3><span className="counter-">{formatNum(totalStaked)}</span> </h3>
                                        <p style={{color: '#28a745', fontSize: '28px'}}>$ {formatNum(totalStakedInUSDT)}</p>
                                        <div style={{color: '#6c757da6', fontSize: '18px'}}>Staked Balance</div>
                                        {/* <span>Expected Returns: </span> <span className="">{formatNum(0)}</span> */}

                                    </div>
                                </div>
                                <div className="single_quick_activity d-flex"  style={{border: '1px solid #ddd', textAlign: 'center'}}>
                                    {/* <div className="icon">
                                        <img src={Vector} alt="" />
                                    </div> */}
                                    <div className="">
                                        <h3><span className="counter-">{formatNum(totalExpectedReturn)}</span> </h3>
                                        <p style={{color: '#28a745', fontSize: '28px'}}>$ {formatNum(totalExpectedReturnInUSDT)}</p>
                                        <p>Expected Returns</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pb-3 mr-3">
                        <button style={{width: 'fit-content', textAlign: 'right'}} className="btn btn-primary btn-lg" onClick={() => dispatch({ type: 'SET_CURRENT_PAGE', payload: 'stake' })}>
                            Deposit XBN for staking
                        </button>
                    </div>
                </div>
            </div>
        {/* </div> */}
    </React.Fragment>
  )
}

export default WalletInfo