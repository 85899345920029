import React from 'react';
import HomeComponent from "Components/HomeComponent";


const HomePage = () => {

    return (
      <React.Fragment>
        <div  style={{ background: '#ffffff' }}>
        <HomeComponent/>
        </div>
      </React.Fragment>
    )
}

export default HomePage;